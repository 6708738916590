import { useEffect, useMemo, useState } from "Shared/haunted/CustomHooks";
import i18next from "i18next";
import { html, useRef } from "haunted";
import { HauntedFunc } from "Shared/haunted/HooksHelpers";
import { revealDelayedElements, toBoolean } from "Services/common";
import { COOKIES, TODOSUMA_MINIMUM_POINTS, thousandSeparatorRegex } from "Services/constants";
import { ref } from "Components/directives/ref";
import { AdminModalType } from "../admin-modal/admin-modal";
import { tealiumLog } from "Services/TealiumHelpers";
import DomCrawlingHelper from "Services/DomCrawlingHelper";
import { deleteTealiumCookie, getCookie } from "Services/cookieHandling";
import { TealiumInfo } from "Shared/models/TealiumInfo";
import { UserInfo } from "Shared/models/UserInfo";
import { DcMembershipType } from "../bancoe-bar/bancoe-bar";
import { TestIdDictionary } from "Services/test-ids/TestIdDictionary";
import { PUB_SUBS } from "Services/pub-sub-service/PubSub";
import numberFormatter from "Services/numberFormatter";
import { PcraLoginInfoModalType } from "Components/pcra-login-info-modal/peru-compra-login-info-modal";
import { useCultureSelector } from "./useCultureSelector";
import classNames from "classnames";
import { decode } from "ComponentHelpers/PromoCodeHelper";

export const useShadowDOM = false;
export const name = "ac-main-menu";

export const observedAttributes: (keyof Attributes)[] = [
	"absolute-action",
	"booking-url",
	"culture",
	"is-banco-estado-on",
	"is-peru-compra-on",
	"is-todo-suma-on",
	"show-menu",
];

export interface Attributes {
	"absolute-action": string;
	"booking-url": string;
	"culture": string;
	"is-banco-estado-on": string;
	"is-peru-compra-on": string;
	"is-todo-suma-on": string;
	"show-menu": string;
}

export interface Props {
	absoluteAction: string;
	bookingUrl: string;
	culture: string;
	isBancoEstadoOn: boolean;
	isPeruCompraOn: boolean;
	isTodoSumaOn: boolean;
	showMenu: boolean;
}

let didScroll = false;
let navbarHeight = 0;
let lastScrollTop = 0;

export const Component: HauntedFunc<Props> = (host) => {
	const props: Props = {
		absoluteAction: host.absoluteAction,
		bookingUrl: host.bookingUrl,
		culture: host.culture,
		isBancoEstadoOn: toBoolean(host.isBancoEstadoOn),
		isPeruCompraOn: toBoolean(host.isPeruCompraOn),
		isTodoSumaOn: toBoolean(host.isTodoSumaOn),
		showMenu: toBoolean(host.showMenu),
	};

	const root = useRef<HTMLDivElement>(undefined);
	const navBar = useRef<HTMLDivElement>(undefined);
	const mobileMenu = useRef<HTMLInputElement>(undefined);

	const [isBancoEstadoBarOpened, setIsBancoEstadoBarOpened] = useState<boolean>(false);
	const [userInfo, setUserInfo] = useState<UserInfo>(undefined);
	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
	const [userName, setUserName] = useState<string>("");
	const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
	const [isSemiCollapsed, setIsSemiCollapsed] = useState<boolean>(false);
	const [isPortalListOpened, setIsPortalListOpened] = useState<boolean>(false);
	const [isBancoEstadoTabOpen, setIsBancoEstadoTabOpen] = useState<boolean>(false);

	const languageAndCountry = (culture = props.culture) => {
		if (!culture) return { language: "es", country: "cl" };

		const [language, country] = culture.split("-");

		return { language: language || "es", country: country || "cl" };
	};

	const isBancoEstado = useMemo(
		() => !isNaN(Number(userInfo?.BancoEstadoCategory)) && Number(userInfo?.BancoEstadoCategory) > 0,
		[userInfo?.BancoEstadoCategory]
	);

	const cultureSelector = useCultureSelector({
		culture: props.culture,
		isBancoEstado,
		userInfo,
		languageAndCountry,
		onOpen: () => {
			setIsPortalListOpened(false);
			mobileMenu.current.checked = false;
			setIsBancoEstadoTabOpen(false);
		},
	});

	// HELPERS

	const profileUrl = () => `${props.bookingUrl}/V2/Profile?culture=${props.culture}&url=${props.absoluteAction}`;

	const bancoEstadoLoginUrl = () =>
		`${props.bookingUrl}/V2/Login?bancoe=1&culture=${props.culture}&url=${props.absoluteAction}`;

	const init = () => {
		const cookieUserName = getUserName();

		if (cookieUserName) {
			setIsLoggedIn(true);
			setUserName(cookieUserName);
		}

		const cookieUserInfo = getUserInfo();

		if (cookieUserInfo) {
			setUserInfo(cookieUserInfo);

			if (toBoolean(cookieUserInfo.IsBancoEstadoMember)) {
				validateRestrictedUrl();
				initBancoEstadoBar(cookieUserInfo);
			}
		}

		tealiumLogInit();
		setAmplitudeDeviceId();

		// eslint-disable-next-line no-console
		console.table(window.JetSmart.Variants);

		// FOR TESTING ONLY! REMOVE BEFORE GOING TO PROD!
		// setIsLoggedIn(true);
		// setUserName("Juan Carlo Pablo Esteban Montoya Figuerroa Verylongname");
		// setUserInfo({
		// 	BancoEstadoCategory: "6",
		// 	BancoEstadoFreeSeats: "4",
		// 	IsBancoEstadoMember: "True",
		// 	IsDiscountClubGroupMember: "False",
		// 	IsDiscountClubStandardMember: "False",
		// 	IsPeruCompraAdmin: "",
		// 	IsPeruCompraMember: "",
		// 	Name: "Juan Carlo Pablo Esteban Montoya Figuerroa Verylongname",
		// 	Organization: "",
		// 	ProgramCodesWithLevels: "",
		// 	ProgramLevels: "",
		// 	RoleCode: "WWWM",
		// 	TodoSumaPoints: "6666",
		// 	PeruCompraAmount: "",
		// });
		// EOF FOR TESTING ONLY

		revealDelayedElements(root.current);

		// https://medium.com/@mariusc23/hide-header-on-scroll-down-show-on-scroll-up-67bbaae9a78c
		window.addEventListener(
			"scroll",
			() => {
				didScroll = true;
				setIsPortalListOpened(false);
			},
			false
		);

		window.setInterval(() => {
			if (didScroll) {
				handleScroll();
				didScroll = false;
			}
		}, 125);

		navbarHeight = navBar?.current.getBoundingClientRect().height || 0;
	};

	const setAmplitudeDeviceId = () => {
		try {
			let cookieDeviceId = getCookie(COOKIES.AMPLITUDE);
			if (cookieDeviceId) {
				if (window.amplitude && typeof window.amplitude.setDeviceId === "function") {
					window.amplitude.setDeviceId(cookieDeviceId);
				} else {
					const amplitudeInterval = window.setInterval(() => {
						if (
							window.amplitude?.getInstance()?._isInitialized &&
							typeof window.amplitude.setDeviceId === "function"
						) {
							cookieDeviceId = getCookie(COOKIES.AMPLITUDE);
							window.clearInterval(amplitudeInterval);
							window.clearTimeout(amplitudeTimeLimit);
							window.amplitude.setDeviceId(cookieDeviceId);
						}
					}, 100);

					const amplitudeTimeLimit = window.setTimeout(() => {
						window.clearInterval(amplitudeInterval);
						// eslint-disable-next-line no-console
						console.error("Could not access Amplitude.");
					}, 10000);
				}
			}
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error("Unknown Amplitude/Tealium cookie problem.");
		}
	};

	const getUserName = () => {
		const cookieUserName = getCookie(COOKIES.USER_NAME);
		return cookieUserName ? decode(atob(cookieUserName)) : "";
	};

	const getOrgName = () => {
		const orgName = getCookie(COOKIES.ORG_NAME);
		return orgName ? decode(atob(orgName)) : "";
	};

	const getUserInfo = () => {
		try {
			const cookieUserInfo = getCookie(COOKIES.USER_INFO);
			const decodedUserInfo = JSON.parse(decode(atob(cookieUserInfo))) as UserInfo;
			return decodedUserInfo;
		} catch (e) {
			return null;
		}
	};

	const getTodoSumaPoints = () =>
		props.isTodoSumaOn && userInfo?.TodoSumaPoints ? Number(userInfo.TodoSumaPoints) : 0;

	const getDcMembershipType = (): DcMembershipType => {
		if (!userInfo) return "None";

		if (toBoolean(userInfo.IsDiscountClubStandardMember)) return "Standard";

		if (toBoolean(userInfo.IsDiscountClubGroupMember)) return "Group";

		return "None";
	};

	const initBancoEstadoBar = (decodedUserInfo: UserInfo) => {
		const mainContent = DomCrawlingHelper.getElemByClass(document.body, "site-content");

		if (
			!mainContent ||
			(decodedUserInfo.BancoEstadoCategory !== "5" && decodedUserInfo.BancoEstadoCategory !== "6")
		) {
			return;
		}

		mainContent.classList.add("dg-bancoe-bar-open");
		setIsBancoEstadoBarOpened(true);
	};

	const validateRestrictedUrl = () => {
		const pathElements = window.location.href.split("/");

		if (pathElements.length < 5) {
			window.location.href = "/cl/es";
			return;
		}

		const countryKey = 3;
		const languageKey = 4;

		if (pathElements[countryKey].toLowerCase() !== "cl" || pathElements[languageKey].toLowerCase() !== "es") {
			pathElements[countryKey] = "cl";
			pathElements[languageKey] = "es";

			const newUrl = pathElements.join("/");

			window.location.href = newUrl;
		}
	};

	const tealiumLogInit = () => {
		const tealiumInfoRaw = getCookie(COOKIES.TEALIUM);

		if (!tealiumInfoRaw) return;

		try {
			const decodedTealiumInfo = JSON.parse(decode(atob(tealiumInfoRaw))) as TealiumInfo;

			tealiumLog({
				eventName: decodedTealiumInfo.Action.toLowerCase() === "login" ? "user_login" : "user_register",
				eventParams: {
					ibe_release: decodedTealiumInfo.IBE_Release,
					organization: decodedTealiumInfo.Organization,
					user_role: decodedTealiumInfo.User_Role,
					user_programs: decodedTealiumInfo.User_Programs.join("|"),
					user_dc_level: decodedTealiumInfo.User_DC_Level,
					user_be_level: decodedTealiumInfo.User_BE_Level,
				},
			});

			deleteTealiumCookie();
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error("Unable to parse tealium info cookie.");
		}
	};

	const isBancoEstadoCategory = (cat: number) => Number(userInfo?.BancoEstadoCategory) === cat;

	const isNewBancoEstadoCategory = () =>
		Number(userInfo?.BancoEstadoCategory) === 5 || Number(userInfo?.BancoEstadoCategory) === 6;

	const todosumaSummary = () => {
		const formattedAmount = getTodoSumaPoints()?.toString().replace(thousandSeparatorRegex, ".") || 0;

		return getTodoSumaPoints() >= TODOSUMA_MINIMUM_POINTS
			? `$${formattedAmount} ${i18next.t("en Puntos TodoSuma")}`
			: `${formattedAmount} ${i18next.t("Puntos TodoSuma")}`;
	};

	const getUserTypeForLoginInfoModal = () =>
		userInfo?.RoleCode === "PECG" ? "gestor" : userInfo?.RoleCode === "PECD" ? "delegado" : "none";

	// EVENT HANDLERS

	const handleScroll = () => {
		const st = window.scrollY;

		if (Math.abs(lastScrollTop - st) < 0) return;

		if (
			st > lastScrollTop &&
			st > navbarHeight * 2 &&
			st <= navbarHeight * 4 &&
			(!mobileMenu.current || !mobileMenu.current.checked)
		) {
			setIsSemiCollapsed(true);
		} else if (
			st > lastScrollTop &&
			st > navbarHeight * 4 &&
			(!mobileMenu.current || !mobileMenu.current.checked)
		) {
			setIsCollapsed(true);
		} else if (st < lastScrollTop && st >= navbarHeight * 4) {
			setIsCollapsed(false);
		} else if ((st < lastScrollTop && st < navbarHeight * 4) || st === 0) {
			setIsCollapsed(false);
			setIsSemiCollapsed(false);
		}

		lastScrollTop = st;
	};

	const handleCheckinModalOpen = () => {
		cultureSelector.closeCountryList();
		setIsPortalListOpened(false);
		mobileMenu.current.checked = false;
		const type: AdminModalType = "checkin";
		PUB_SUBS.AdminModalOpened.publish({ modalType: type });
	};

	const handleAdminModalOpen = () => {
		cultureSelector.closeCountryList();
		setIsPortalListOpened(false);
		mobileMenu.current.checked = false;
		const type: AdminModalType = "admin";
		PUB_SUBS.AdminModalOpened.publish({ modalType: type });
	};

	const handleLoginInfoModalOpen = () => {
		const loginCookie = getCookie(COOKIES.PERU_COMPRA_LOGIN);
		if (loginCookie === "true") {
			mobileMenu.current.checked = false;
			const type: PcraLoginInfoModalType = getUserTypeForLoginInfoModal();
			PUB_SUBS.PcraLoginInfoModalOpened.publish({ modalType: type });
		}
	};

	const handlePortalListOpen = () => {
		if (isBancoEstado) return;

		setIsPortalListOpened(!isPortalListOpened);
		cultureSelector.closeCountryList();
		setIsBancoEstadoTabOpen(false);
	};

	const handleMobileMenuClick = () => {
		cultureSelector.closeCountryList();
		setIsPortalListOpened(false);
		setIsBancoEstadoTabOpen(false);
	};

	const handleBancoEstadoTabClick = () => {
		setIsBancoEstadoTabOpen(!isBancoEstadoTabOpen);
		cultureSelector.closeCountryList();
		setIsPortalListOpened(false);
	};

	const handleLogout = () => {
		tealiumLog({
			eventName: "user_logout",
			eventParams: {},
		});

		window.setTimeout(
			() => (window.location.href = `${props.bookingUrl}/Member/Logout?url=${props.absoluteAction}`),
			500
		);
	};

	const toggleBancoEstadoBar = (e: MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		setIsBancoEstadoBarOpened(!isBancoEstadoBarOpened);

		const mainContent = DomCrawlingHelper.getElemByClass(document.body, "site-content");

		if (mainContent) mainContent.classList.toggle("dg-bancoe-bar-open");
	};

	useEffect(() => {
		if (userInfo?.IsPeruCompraAdmin || userInfo?.IsPeruCompraMember) {
			window.setTimeout(handleLoginInfoModalOpen, 500);
		}
	}, [userInfo]);

	useEffect(init, []);

	// TEMPLATES

	const logoTemplate = () => {
		const { language, country } = languageAndCountry();
		const baseUrl = `/${country}/${language}`;

		return html`
			<a class="dg-jetsmart-logo" href=${baseUrl}>
				<img src="/images/header/jetsmart-logo-colored.svg" alt="JetSMART" />
			</a>
		`;
	};
	const menuTemplate = () =>
		props.showMenu
			? html`
					<div class="menu-container">
						<input
							ref=${ref(mobileMenu)}
							type="checkbox"
							class="mobile-navigation"
							@click=${handleMobileMenuClick}
						/>
						<div class="right">${menuItemsTemplate()}</div>
						<div class="mobile-navigation closed">${i18next.t("layout-menu")}</div>
						<div class="mobile-navigation opened">${i18next.t("layout-close")}</div>
						${cultureSelector.htmlTemplate()}
					</div>
			  `
			: "";

	const menuItemsTemplate = () => html`
		<ul class="login-container">
			<li>
				<a @click=${handleCheckinModalOpen} data-test-id=${TestIdDictionary.Menu.CheckinOpener}>
					<span>${i18next.t("layout-check-in")}</span>
				</a>
			</li>
			<li>
				<a @click=${handleAdminModalOpen}>
					<span>${i18next.t("layout-manage-trip")}</span>
				</a>
			</li>
			${menuForAnonymousUsersTemplate()} ${menuForLoggedInUsersTemplate()}
		</ul>
	`;

	const portalMenuOpenerTemplate = () => html`
		<a class=${classNames("dg-header-dropdown-label portal", { opened: isPortalListOpened })}>
			<span class="dg-header-dropdown-label-title">${i18next.t("Portal")}</span>
		</a>
	`;

	const portalMenuDropdownTemplate = () => {
		const agencyLoginUrl = `${props.bookingUrl}/V2/Login?agency=1&culture=${props.culture}&url=${props.absoluteAction}`;
		const companyLoginUrl = `${props.bookingUrl}/V2/Login?company=1&culture=${props.culture}&url=${props.absoluteAction}`;

		return html`
			<div class=${classNames("dg-header-dropdown-list", { opened: isPortalListOpened })}>
				<a href=${companyLoginUrl}> ${i18next.t("Companies")} </a>
				<a href=${agencyLoginUrl}> ${i18next.t("Agencies")} </a>
			</div>
		`;
	};

	const portalMenuTemplate = () => html`
		<li class="dg-header-dropdown-opener" @click=${handlePortalListOpen}>
			${portalMenuOpenerTemplate()} ${portalMenuDropdownTemplate()}
		</li>
	`;

	const bancoEstadoMenuOpenerTemplate = () => html`
		<a class=${classNames("dg-header-dropdown-label", { opened: isBancoEstadoTabOpen })}>
			<span class="dg-header-dropdown-label-title">
				${i18next.t("layout-login-bancoe")} <span>BancoEstado</span>
			</span>
		</a>
	`;

	const bancoEstadoMenuDropdownTemplate = () => html`
		<div class=${classNames("dg-header-dropdown-list", { opened: isBancoEstadoTabOpen })}>
			<a href=${bancoEstadoLoginUrl()}> ${i18next.t("layout-register")} </a>
			<a href=${bancoEstadoLoginUrl()}> ${i18next.t("layout-login")} </a>
		</div>
	`;

	const bancoEstadoMenuTemplate = () =>
		props.isBancoEstadoOn && props.culture.toLowerCase() === "es-cl"
			? html`
					<li class="dg-header-dropdown-opener" @click=${handleBancoEstadoTabClick}>
						${bancoEstadoMenuOpenerTemplate()} ${bancoEstadoMenuDropdownTemplate()}
					</li>
			  `
			: "";

	const peruCompraLoginMenuTemplate = () => {
		const peruCompraLoginUrl = `${props.bookingUrl}/V2/Login?pcompra=1&culture=${props.culture}&url=${props.absoluteAction}`;

		return props.culture.toLowerCase() === "es-pe" && props.isPeruCompraOn
			? html`
					<li>
						<a href=${peruCompraLoginUrl} data-test-id=${TestIdDictionary.Menu.PeruCompraLink}>
							<span>${i18next.t("layout-perucompra")}</span>
						</a>
					</li>
			  `
			: "";
	};

	const menuForAnonymousUsersTemplate = () => {
		const loginUrl = `${props.bookingUrl}/V2/Login?culture=${props.culture}&url=${props.absoluteAction}`;

		return !isLoggedIn
			? html`
					${portalMenuTemplate()} ${peruCompraLoginMenuTemplate()}
					<li>
						<a href=${loginUrl} data-test-id=${TestIdDictionary.Menu.LoginOpener}>
							<span>${i18next.t("layout-login")}</span>
						</a>
					</li>
					${bancoEstadoMenuTemplate()}
			  `
			: "";
	};

	const menuForLoggedInUsersTemplate = () =>
		isLoggedIn
			? html`${bancoEstadoProfileTemplate()} ${profileTemplate()} ${peruCompraUserInfoTemplate()}
			  ${logoutTemplate()}`
			: "";

	const todoSumaSummaryTemplate = () =>
		props.isTodoSumaOn ? html` <span class="header-todosuma-summary"> ${todosumaSummary()} </span>` : "";

	const bancoEstadoProfileTemplate = () => {
		const tempClassNames = classNames("user-data-banco-estado", {
			"be-pointer-6": isBancoEstadoCategory(6),
			"be-pointer-5": isBancoEstadoCategory(5),
			"pointer-squash": !isBancoEstadoBarOpened,
		});

		const imgUrl = isBancoEstadoCategory(6)
			? "/images/banco-estado/logo-smart-plus-bg-gray.png"
			: isBancoEstadoCategory(5)
			? "/images/banco-estado/logo-smart-bg-orange.png"
			: "";

		return isNewBancoEstadoCategory()
			? html`
					<li class=${tempClassNames}>
						<a href=${profileUrl()}>
							<span class="dg-username">
								${imgUrl ? html`<img src=${imgUrl} />` : ""} ${userName} ${bancoEstadoArrowTemplate()}
							</span>
							${todoSumaSummaryTemplate()}
						</a>
					</li>
			  `
			: "";
	};

	const bancoEstadoArrowTemplate = () =>
		html`
			<i
				class=${classNames("hidden-sm-down jsh-icon jsh-chevron-right point-down", {
					"point-up": !isBancoEstadoBarOpened,
				})}
				@click=${toggleBancoEstadoBar}
			></i>
		`;

	const profileTemplate = () =>
		!isNewBancoEstadoCategory() && !userInfo?.IsPeruCompraAdmin && !userInfo?.IsPeruCompraMember
			? html`
					<li class="user-data">
						<a href=${profileUrl()}>
							<span class=${classNames("dg-username", { "dg-banco-estado-name": isBancoEstado })}>
								${userName}
							</span>
							${employeeOrgTemplate()} ${cug2OrgNameTemplate()} ${bancoEstadoOrgNameTemplate()}
						</a>
					</li>
			  `
			: "";

	const peruCompraUserInfoTemplate = () => {
		const peruCompraProfileUrl = `${props.bookingUrl}/V2Agency/Summary?culture=${props.culture}&url=${props.absoluteAction}`;

		return userInfo?.IsPeruCompraAdmin || userInfo?.IsPeruCompraMember
			? html`
					<li class="user-data">
						<a href=${peruCompraProfileUrl}>
							<span class="dg-username">${userName}</span>
							<span class="orgname">
								${userInfo.IsPeruCompraAdmin ? i18next.t("Gestor") : i18next.t("Delegado")} /
								${i18next.t("Monto {{-availableAmount}}", {
									availableAmount: numberFormatter({
										amount: Number(userInfo.PeruCompraAmount),
										culture: props.culture,
										currency: "USD",
									}),
								})}
							</span>
						</a>
					</li>
			  `
			: "";
	};

	const employeeOrgTemplate = () =>
		userInfo?.RoleCode === "EMPL" ? html`<span class="orgname">Team JetSMART</span>` : "";

	const cug2OrgNameTemplate = () => (getOrgName() ? html`<span class="orgname">${getOrgName()}</span>` : "");

	const bancoEstadoOrgNameTemplate = () =>
		isBancoEstado
			? html`<span class="dg-banco-estado-org-name">${i18next.t("layout-login-bancoe-orgname")}</span>`
			: "";

	const logoutTemplate = () => html`
		<li>
			<a href="#" @click=${handleLogout} data-test-id=${TestIdDictionary.Menu.LogoutOpener}>
				<span>${i18next.t("layout-logout")}</span>
			</a>
		</li>
	`;

	return html`
		<header
			ref=${ref(root)}
			class=${classNames("main-header", { "collapsed": isCollapsed, "semi-collapsed": isSemiCollapsed })}
		>
			<div ref=${ref(navBar)} class="nav-bg delayed">
				<section class="nav-container full-width">${logoTemplate()} ${menuTemplate()}</section>
			</div>
			<ac-bancoe-bar
				.bancoEstadoCategory=${Number(userInfo?.BancoEstadoCategory) || 0}
				.dcMembershipType=${getDcMembershipType()}
				.freeSeats=${Number(userInfo?.BancoEstadoFreeSeats || 0)}
				.isBarOpen=${isBancoEstadoBarOpened}
				.isTodoSumaOn=${props.isTodoSumaOn}
				.todoSumaPointsAmount=${getTodoSumaPoints()}
			></ac-bancoe-bar>
		</header>
	`;
};

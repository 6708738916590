import { useNavitaireSettings } from "ComponentHelpers/useNavitaireSettings";
import {
	ARGENTINIAN_CULTURE_CODE,
	PERUVIAN_CULTURE_CODE,
	COLOMBIAN_CULTURE_CODE,
	BRASILIAN_CULTURE_CODE,
	USA_CULTURE_CODE,
	URUGUAYAN_CULTURE_CODE,
	PARAGUAYAN_CULTURE_CODE,
	ECUADORIAN_CULTURE_CODE,
	CHILEAN_CULTURE_CODE,
	BOLIVIAN_CULTURE_CODE,
	COSTA_RICAN_CULTURE_CODE,
	GUATEMALAN_CULTURE_CODE,
	HONDURAN_CULTURE_CODE,
	MEXICAN_CULTURE_CODE,
	NICARAGUAN_CULTURE_CODE,
	PANAMANIAN_CULTURE_CODE,
	SALVADORAN_CULTURE_CODE,
	VENEZUELAN_CULTURE_CODE,
} from "Services/constants";
import { useMemo, useState } from "Shared/haunted/CustomHooks";
import { UserInfo } from "Shared/models/UserInfo";
import { html } from "lit-html";
import { isCultureEnabled, getSelectableCulturesInOrder, mapToCultureListItem } from "ComponentHelpers/CultureHelper";
import i18next from "i18next";
import { useRef } from "haunted";
import { ref } from "Components/directives/ref";
import classNames from "classnames";

export interface Props {
	culture: string;
	isBancoEstado: boolean;
	userInfo: UserInfo;
	languageAndCountry: (culture: string) => { language: string; country: string };
	onOpen: () => void;
}

export interface CultureListItem {
	culture: string;
	name: string;
}

export const useCultureSelector = (props: Props) => {
	const listElem = useRef<HTMLDivElement>(undefined);
	const navitaireSettings = useNavitaireSettings();

	const [isCountryListOpened, setIsCountryListOpened] = useState<boolean>(false);

	const cultureList: { culture: string; name: string }[] = useMemo(() => {
		const list = [
			{ culture: ARGENTINIAN_CULTURE_CODE, name: i18next.t("Argentina") },
			{ culture: BOLIVIAN_CULTURE_CODE, name: i18next.t("Bolivia") },
			{ culture: BRASILIAN_CULTURE_CODE, name: i18next.t("Brasil") },
			{ culture: CHILEAN_CULTURE_CODE, name: i18next.t("Chile") },
			{ culture: COLOMBIAN_CULTURE_CODE, name: i18next.t("Colombia") },
			{ culture: COSTA_RICAN_CULTURE_CODE, name: i18next.t("Costa Rica") },
			{ culture: ECUADORIAN_CULTURE_CODE, name: i18next.t("Ecuador") },
			{ culture: GUATEMALAN_CULTURE_CODE, name: i18next.t("Guatemala") },
			{ culture: HONDURAN_CULTURE_CODE, name: i18next.t("Honduras") },
			{ culture: MEXICAN_CULTURE_CODE, name: i18next.t("México") },
			{ culture: NICARAGUAN_CULTURE_CODE, name: i18next.t("Nicaragua") },
			{ culture: PANAMANIAN_CULTURE_CODE, name: i18next.t("Panamá") },
			{ culture: PARAGUAYAN_CULTURE_CODE, name: i18next.t("Paraguay") },
			{ culture: PERUVIAN_CULTURE_CODE, name: i18next.t("Perú") },
			{ culture: SALVADORAN_CULTURE_CODE, name: i18next.t("El Salvador") },
			{ culture: URUGUAYAN_CULTURE_CODE, name: i18next.t("Uruguay") },
			{ culture: USA_CULTURE_CODE, name: i18next.t("English") },
			{ culture: VENEZUELAN_CULTURE_CODE, name: i18next.t("Venezuela") },
		];

		return list.filter((listItem) => isCultureEnabled(navitaireSettings?.value?.cultureSettings, listItem.culture));
	}, [navitaireSettings?.value?.cultureSettings]);

	const cultures = useMemo(
		() => getSelectableCulturesInOrder(navitaireSettings?.value?.cultureSettings, cultureList, props.culture),
		[cultureList, props.culture, navitaireSettings?.value?.cultureSettings]
	);

	// Helpers

	const canSwitchCulture = () =>
		!props.isBancoEstado && !props.userInfo?.IsPeruCompraAdmin && !props.userInfo?.IsPeruCompraMember;

	// Events

	const handleCountryListOpen = () => {
		if (!canSwitchCulture()) return;

		setIsCountryListOpened(!isCountryListOpened);
		props.onOpen();
	};

	// Templates

	const cultureTemplate = (culture: CultureListItem) => html`
		<img class=${classNames("flag", `flag-${culture.culture.substring(3)}`)} />
		<span> ${culture.name}</span>
	`;

	const openCultureTemplate = () => {
		const selectedCulture = mapToCultureListItem(cultureList, props.culture);
		return cultureTemplate(selectedCulture);
	};

	const cultureOptionTemplate = (culture: CultureListItem) => {
		const { language, country } = props.languageAndCountry(culture.culture);
		const url = `/ChangeLanguage?newCountryCode=${country}&newLanguagecode=${language}`;

		return html`<a href=${url}>${cultureTemplate(culture)}</a>`;
	};

	const cultureDropdownTemplate = () =>
		canSwitchCulture()
			? html`
					<div
						ref=${ref(listElem)}
						class="cultures"
						style="max-height: ${isCountryListOpened ? `${listElem.current.scrollHeight}px` : "0"};"
					>
						<ul>
							${cultures.map(cultureOptionTemplate)}
						</ul>
					</div>
			  `
			: "";

	const htmlTemplate = () =>
		cultures.length > 0
			? html`
					<div class="dg-culture-dropdown-opener">
						<a
							@click=${handleCountryListOpen}
							class=${classNames("menu-button with-flag", {
								"opened": isCountryListOpened,
								"no-arrow": !canSwitchCulture(),
							})}
						>
							${openCultureTemplate()}
						</a>
						${cultureDropdownTemplate()}
					</div>
			  `
			: "";

	return { closeCountryList: () => setIsCountryListOpened(false), htmlTemplate };
};
